import React from 'react'

import Layout from '../components/Layout'

const ContactPage = () => (
  <Layout>
    <h1>Contact</h1>
    <p>
      Should you have any questions about our projects, how you can get involved and contribute, 
      please do not hesitate to contact us.
    </p>
    <h2>Contact persons</h2>
    <p>
      Elvis G. Morris<br />
      Luther B. Tarpeh<br />
    </p>
    <h2>Email</h2>
    <p><a href='mailto:info@check-one.org'>info@check-one.org</a></p>
    <h2>Phone</h2>
    <p>+231 (0)886 424335 / +231 (0)770 352597 / +231 (0)880 830126 / +231 (0)880 829518</p>
    <h2>Address</h2>
    <p>
      Check One Foundation<br />
      SKD Boulevard (Opposite Duncan Gas Station)<br />
      Swankamore Community, Oldest Congo Town<br />
      Monrovia<br />
      LIBERIA<br />
    </p>
  </Layout>
)

export default ContactPage
